const navbarShadowOnScroll = () => {
  const navbar = document.querySelector('.navbar');
  if (navbar) { window.addEventListener ('scroll', () => {
    if (window.scrollY > 10) {
      navbar.classList.add('scrolled');
    } else {
      navbar.classList.remove('scrolled');
    }
  })
};
}

export { navbarShadowOnScroll };
